<template>
  <div>
    <Breadcrumbs main="" title="Blacklist Affiliate Users" />
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <b-button
            variant="primary"
            class="btn"
            @click="aff_blklist_modal = true"
            >Add User +</b-button
          >
          <b-row class="mt-3 col-md-12">
                <b-col class="col-md-6 col-12">
                  <b-input-group>
                    <b-input-group-prepend >
                      <span class="input-group-text search-bg"><i class="icofont icofont-search-alt-2"></i></span>
                    </b-input-group-prepend>
                    <b-form-input
                      class="col-md-5"
                      v-model="filter"
                      placeholder="Type to Search"
                      type="search"
                    ></b-form-input>
                  </b-input-group>
                </b-col>

                <b-col class="text-right col-md-6 col-12">
                  <b-form-group
                    label-cols="10"
                    label="Per page"
                    class="mb-0"
                  >
                    <b-form-select
                      class="col-md-10"
                      v-model="perPage"
                      :options="pageOptions"
                      @change="getblkaffuser"
                    ></b-form-select>
                  </b-form-group>
                </b-col>
              </b-row>
              <div class="col-md-12" v-if="loading">
                <h6 class="sub-title mb-0 text-center">Loading...</h6>
                  <div class="loader-box">
                    <div class="loader-30"></div>
                  </div>
              </div> 
              <div v-else class="table-responsive datatable-vue col-md-12 text-left">
                <b-table
                  show-empty
                  stacked="md"
                  :items="items"
                  :filter="filter"
                  :fields="tablefields"
                  :per-page="perPage"
                  @filtered="onFiltered"
                >
                <template #table-caption>Affiliate List.</template>

                <template #cell(userid)="data">
                  <b class="text-danger">{{ (users_opt.filter((user) => user.value === data.item.userid)[0]?.text || '') }}</b>
                </template>
                <template #cell(createdAt)="data">
                  <b>{{ changeDateFormate(data.item.createdAt) }}</b>
                </template>
                <template #cell(action)="data">
                  <feather class="text-danger" type="trash-2" title="Delete" @click="deleteblklstuser(data.item._id)"></feather>
                </template>
                </b-table>
              </div>

              <b-col md="12" class="mt-3 text-right p-0">
                <div class="pagination">
                  <a v-show="currentPage != 1" @click="getblkaffuser('prev')">&laquo; Back</a>
                  <a class="active">{{currentPage}}</a>
                  <a v-show="items.length >= 1" @click="getblkaffuser('next')">Next &raquo;</a>
                </div>
              </b-col>
        </div>
      </div>
    </div>
    <b-modal id="blklist-affiliate" size="lg" title="Blacklist User" class="theme-modal" @hidden="closeModal()" v-model="aff_blklist_modal" no-close-on-backdrop hide-footer>
     
      <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <form action="" @submit.prevent="onSubmit()">
                    <px-card>
                            <b-row class="mb-2">
                                <b-col md="12" class="font-weight-bold">
                                    Select user for Blacklist
                                </b-col>
                                <b-col md="12" class="mt-1">
                                  <b-form-select name="type" size="sm" v-model="selectedUser" :options="users_opt" required></b-form-select>
                                </b-col>
                            </b-row>
                            <b-row class="mt-3 text-center">
                                <b-col>
                                    <b-button type="submit" variant="primary">Add to Blacklist</b-button>
                                </b-col>
                            </b-row>
                      </px-card>
                  </form>
              </div>
            </div>
          </div>
            
        <template #modal-footer>
        <b-button @click="closeModal()" color="danger">Close</b-button>
        </template>
      </b-modal>
  </div>
</template>




<script>
import moment from "moment";
import CreateAffiliate from "./CreateAffiliate.vue"
import ManagerController from "../../../services/managerController";
import UserController from "../../../services/userController";
export default {
  name: "Affiliate",
  components:{CreateAffiliate},
  data(){
    return{
        aff_blklist_modal:false,
        affiliate_data:{},
        loading:false,
        items:[],
        totalRows:0,
        filter:null,
        currentPage: 1,
        perPage: 10,
        pageOptions: [5, 10, 50],
        tablefields: [
                { key: "userid", label: "User", sortable: true },
                { key: "createdAt", label: "Created At", sortable: true },
                { key: "action", label: "Action", sortable: false },
        ],
        selectedUser:null,
        users_opt:[{ value: null, text:'select user' }],
        allitems:[]
    }
  },
  mounted(){
    this.getUsers();
    this.getblkaffuser();
  },
  methods:{
    async onSubmit(){
        let payload = {'affliateUserId': this.selectedUser}
        let response = await ManagerController.addBlacklist(payload);
        if(response.result){
          this.$toasted.show(response.message, {theme: 'outline',position: "bottom-center", type: 'success', duration: 2000});
          this.closeModal();
        }
    },
    async getblkaffuser(txt){
      this.loading = true;
      this.items = []
      if(txt == 'next'){this.currentPage ++}
      if(txt == 'prev'){
        if(this.currentPage != 1){
        this.currentPage --
        }
      }
      var paginationQuery = '?currentPage='+this.currentPage+'&pageSize='+this.perPage
      let response = await ManagerController.getblkaffuser(paginationQuery)
      if (response.result) {
                this.items = response.data;
                this.allitems = response.data;
                this.totalRows = this.items.length;
            }
      this.loading = false;
    },
    async deleteblklstuser(id){
          if(confirm('Are you sure to delete this user from blacklist?')){
          this.loading = true;
          let payload = {'blackListedAffId': id}
          let response = await ManagerController.deleteblklstuser(payload);
          if(response.result){
            this.$toasted.show(response.message, {theme: 'outline',position: "bottom-center", type: 'success', duration: 2000});
          }
          this.getblkaffuser()
        }
    },
    closeModal(){
        this.aff_blklist_modal = false
        this.getblkaffuser();
    },
    changeDateFormate(d) {
      return moment(d).format("D/M/YYYY, HH A");
    },
    onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
    },
    customFilter() {
      let search = this.filter.toString().toLowerCase()
      // search user name from user_opt array
      let user = this.users_opt.filter((user) => user.text.toLowerCase().includes(search))
      // now filter items array with user id from user array of obejcts which incluse user id in value
      this.items = this.allitems.filter((item) => user.map((u) => u.value).includes(item.userid))
      
      
    },
    async getUsers(){
      let response = await UserController.getAllUsers()
      if (response.result) {
          this.users = response.message.filter(t=>{
            if(t.role != 'manager'){
                  return true;
              }
          });
          this.users.map((p)=> {
            this.users_opt.push({'value':p._id,'text':p.username+' ('+p.email+')'})
          })
      }
      else{
        this.$toasted.show(response.message, {theme: 'outline',position: "bottom-center", type: 'error', duration: 2000});
      }
      this.getblkaffuser();
    },
  }
};
</script>